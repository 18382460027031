import bit from "../../../Assets/images/chipAI.png";
import shield from "../../../Assets/images/shiledBadge.png";
import cake from "../../../Assets/images/cakeSlice.png";

export const rowDataAI = {
  title: "Robust AI Infrastructure",
  subTitle:
    "Our scalable AI platform can handle the intense demands of institutional trading.",
  points: [
    {
      title:
        "Ensure reliable operation with our robust AI platform designed for high-volume trading.",
    },
    { title: "Achieve higher throughput with our scalable AI infrastructure." },
    {
      title:
        "Experience the confidence of institutional-grade technology supporting your trading activities.",
    },
  ],
  picture: bit,
};

export const rowData = {
  title: "Harness Mathematical Thruth",
  subTitle:
    "Leverage AI's computational precision to conquer the financial markets.",
  points: [
    {
      title:
        "Capitalize on complex patterns with our AI's advanced pattern recognition.",
    },
    {
      title:
        "Enable precise execution with millisecond-level trading algorithms.",
    },
    {
      title:
        "Benefit from the consistent performance of math-based strategies.",
    },
  ],
  picture: shield,
};

export const options = {
  title: "Quantitative Scientists",
  subTitle:
    "Trust in our team of expert data scientists crafting effective AI strategies.",
  btnText: "Contact us Now",
  picture: cake,
  data: [
    {
      title:
        "Enjoy peace of mind knowing our team comprises experts in AI, machine learning, and data analysis.",
    },
    {
      title:
        "Benefit from the constant refinement of our AI models, ensuring they remain optimized and effective.",
    },
    {
      title:
        "Experience advanced strategies developed using rigorous scientific methods.",
    },
  ],
};
