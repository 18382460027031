import React from "react";

//Components
import LoaderDefaultImage from "./LoaderDefaultImage/LoaderDefaultImage";

// Styles
import Style from "./Loader.module.css";

const Loader = ({ customLoader, style, children, loading = true }) => {
  return (
    <div style={style} className={Style.loader}>
      <div className={Style.defaultLoaderImage}>
        {loading ? (
          !customLoader ? (
            <LoaderDefaultImage />
          ) : (
            customLoader
          )
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default Loader;
