import React from "react";

// Assets
import LogoLoader from "../../../../Assets/logo.svg";

//Styles
import Style from "./LoaderDefaultImage.module.css";

const LoaderDefaultImage = () => {
  return (
    <div className={Style.loaderContainer}>
      <img alt="" src={LogoLoader} />
    </div>
  );
};

export default LoaderDefaultImage;
