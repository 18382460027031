import React from "react";

// Libraries
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { Container } from "@hybris-software/ui-kit";

// utils
import classNames from "../../../Utils/classNames";

// Images
import user from "../../../Assets/images/reviewUserImg.png";
import One from "../../../Assets/images/1.png";
import Two from "../../../Assets/images/2.png";
import Three from "../../../Assets/images/3.png";
import Four from "../../../Assets/images/4.png";
import Five from "../../../Assets/images/5.png";
import Six from "../../../Assets/images/6.png";
import Seven from "../../../Assets/images/7.png";
import Eight from "../../../Assets/images/8.png";
import Nine from "../../../Assets/images/9.png";
import Ten from "../../../Assets/images/10.png";
import Eleven from "../../../Assets/images/11.png";
import Twelve from "../../../Assets/images/12.png";
import Thirteen from "../../../Assets/images/13.png";
import Fourteen from "../../../Assets/images/14.png";
import Fifteen from "../../../Assets/images/15.png";
import Sixteen from "../../../Assets/images/16.png";
import Seventeen from "../../../Assets/images/17.png";
import Eighteen from "../../../Assets/images/18.png";
import NIneteen from "../../../Assets/images/19.png";
import beSmartBG from "../../../Assets/images/beSmartBG.png";
import beSmartBGTwo from "../../../Assets/images/smartBGTwo.png";

// Icons
import star from "../../../Assets/icons/Star.svg";

// Styles
import "swiper/css";
import "swiper/css/autoplay";
import Style from "./BeSmarter.module.css";
import CustomButton from "../CustomButton/CustomButton";
import Smart from "../Smart/Smart";

const data = [
  {
    text: "Found new confidence in crypto trading with QuantWise. Definitely recommend it!",
    img: One,
    fullName: "John Baker",
    position: "Engineer",
    rating: 4.5,
  },
  {
    text: "Gained much more than expected from my annual CryptoGPT subscription. Excellent journey so far.",
    img: Two,
    fullName: "Emily Davis",
    position: "Marketing Specialist",
    rating: 4.6,
  },
  {
    text: "Thanks to QuantWise, I'm making steady gains without much hassle.",
    img: Three,
    fullName: "Richard Turner",
    position: "Finance Consultant",
    rating: 4.3,
  },
  {
    text: "QuantWise's AI strategies significantly improved my trading performance!",
    img: Four,
    fullName: " Sarah Peterson",
    position: "Web Developer",
    rating: 4.7,
  },
  {
    text: "User-friendly interface and helpful support from QuantWise have made my trading experience better.",
    img: Five,
    fullName: "Mark Bailey",
    position: "Freelancer ",
    rating: 4.5,
  },
  {
    text: "Finally, a platform that makes crypto trading simple and profitable. Thank you, QuantWise!",
    img: Six,
    fullName: "Jullian Green",
    position: "Teacher",
    rating: 4.5,
  },
  {
    text: "Started using QuantWise, and my portfolio is looking better than ever",
    img: Seven,
    fullName: "Thomas Fisher",
    position: "Entrepreneur",
    rating: 4.9,
  },
  {
    text: "Love how QuantWise makes complex trading strategies easy to understand. My profits are on the rise!",
    img: Eight,
    fullName: "Mark Hughes",
    position: "Real Estate Agent",
    rating: 4.2,
  },
  {
    text: "My annual subscription to CryptoGPT was one of the best investments I made last year. QuantWise is excellent!",
    img: Nine,
    fullName: "Michael Russell",
    position: "Graphic Designer",
    rating: 4.6,
  },
  {
    text: "Using QuantWise has made my crypto trading journey a breeze. Definitely going to stick around!",
    img: Ten,
    fullName: "David Watson",
    position: "Accountant",
    rating: 4.5,
  },
  {
    text: "The performance of the strategies in QuantWise have exceeded my expectations. Looking forward to another profitable year!",
    img: Eleven,
    fullName: "Sam Brooks",
    position: "Retail Manager",
    rating: 4.3,
  },
  {
    text: "QuantWise has made crypto trading not just profitable but enjoyable too. Keep up the great work!",
    img: Twelve,
    fullName: "Chris Murphy",
    position: "Software Developer",
    rating: 4.7,
  },
  {
    text: "With QuantWise, I can trade confidently, and the steady gains are proof!",
    img: Thirteen,
    fullName: "Kevin Bryant",
    position: "HR Professional",
    rating: 4.4,
  },
  {
    text: "What I love about QuantWise is its simplicity. It's easy to use, and the profits are great!",
    img: Fourteen,
    fullName: "Mu Chen",
    position: "Doctor",
    rating: 4.1,
  },
  {
    text: "My profits have steadily grown since I started using QuantWise. Highly recommended!",
    img: Fifteen,
    fullName: "Jeoffrey Jenkins",
    position: "Nurse",
    rating: 4.8,
  },
  {
    text: "Thanks to QuantWise, I feel like I'm a part of the future of trading. It's a great platform for anyone into crypto.",
    img: Sixteen,
    fullName: "Kumiko Mei",
    position: "Data Analyst",
    rating: 4.0,
  },
  {
    text: "QuantWise has truly made crypto trading straightforward and profitable. Happy to be part of this platform!",
    img: Seventeen,
    fullName: "Chris Ferguson",
    position: "Sales Executive",
    rating: 4.5,
  },
  {
    text: "The AI-based strategies in QuantWise have significantly improved my trades. The results are amazing!",
    img: Eighteen,
    fullName: "Anthony Sullivan",
    position: "Writer",
    rating: 4.6,
  },
  {
    text: "QuantWise has made my crypto journey enjoyable and profitable. Can't wait to see what the next year brings!",
    img: NIneteen,
    fullName: "Luiz Gonzalez",
    position: "Photographer",
    rating: 4.7,
  },
];

const rows = [data, data, data];

const swiperSettings = {
  // slidesPerView: "auto",
  centeredSlides: true,
  spaceBetween: 15,
  speed: 20500,
  loop: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    576: {
      slidesPerView: 2,
    },
    991: {
      slidesPerView: 3,
    },
    1440: {
      slidesPerView: 4,
    },
    1900: {
      slidesPerView: 6,
    },
    2000: {
      slidesPerView: 8,
    },
  },
  autoplay: {
    delay: 1,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  modules: [Autoplay],
  style: {
    marginTop: "25px",
  },
};

const BeSmarter = ({ slider }) => {
  return (
    <div className={Style.smart}>
      {/* TODO : get grouped images on the left from designers  */}
      <img
        style={{
          display: slider ? "block" : "none",
        }}
        src={beSmartBG}
        alt=""
      />
      <img
        style={{
          display: slider ? "block" : "none",
        }}
        src={beSmartBGTwo}
        alt=""
      />
      <div
        style={{
          display: slider ? "block" : "none",
        }}
        className={Style.headings}
      >
        <h1>Be Smarter, Not Harder with Quantwise</h1>
        <h6>
          Get started with Quantwise - AI today and experience the power of AI
          in your portfolio!
        </h6>
      </div>
      <div className={slider ? Style.partWrapper : ""}>
        <Smart />
      </div>

      <div
        style={{
          display: slider ? "block" : "none",
        }}
        className={Style.cards}
      >
        {rows.map((row, index) => {
          let settings = { ...swiperSettings };
          if (index % 2 !== 0) {
            settings = {
              ...swiperSettings,
              autoplay: {
                ...swiperSettings.autoplay,
                reverseDirection: true,
              },
            };
          }
          return (
            <Swiper
              {...settings}
              // style={{
              //   height: index === 2 ? "250px" : "",
              // }}
              key={index}
            >
              {row.map((slide, index) => (
                <SwiperSlide key={index}>
                  <ReviewCard {...slide} />
                </SwiperSlide>
              ))}
            </Swiper>
          );
        })}
      </div>
    </div>
  );
};

const ReviewCard = ({
  title,
  company,
  text,
  img,
  fullName,
  position,
  rating,
}) => {
  return (
    <div className={Style.cardWrapper}>
      <div className={Style.card}>
        {/* <div className={Style.company}>{company}</div> */}
        <h4>{title}</h4>
        <p>{text}</p>
        <div className={Style.separator} />
        <div className={Style.user}>
          <img src={img} alt="user" />
          <div className={Style.userDetails}>
            <p>{fullName}</p>
            <p>{position}</p>
          </div>
          <div className={Style.rating}>
            <img src={star} alt="" />
            <p>{rating}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeSmarter;
