import React, { useRef } from "react";

// Libraries
import { useWindowSize, MagicModal } from "@hybris-software/ui-kit";

// Components
import CustomButton from "../../UIKit/CustomButton/CustomButton";
import ModalBody from "../ModalBody/ModalBody";

// Images
import leftBlackHole from "../../../Assets/images/leftBlackHole.png";

// Icons
import pinkCheck from "../../../Assets/icons/pinkCheck.svg";
import { IoCloseSharp } from "react-icons/io5";

// Styles
import Style from "./CustomRow.module.css";
import { Container } from "@hybris-software/ui-kit";

const CustomRow = ({ data }) => {
  const width = useWindowSize();
  const modalRef = useRef(null);

  const handleButtonClick = () => {
    modalRef.current.updateBody(<ModalBody newRef={modalRef} />);
  };

  const modalStyle = {
    maxWidth: "1200px",
    borderRadius: "15px",
    border: "2px solid rgba(255, 255, 255, 0.05)",
    background: "#020203",
    flexShrink: "0",
  };

  const overlayStyle = {
    background: "rgba(0, 0, 0, 0.80)",
    backdropFilter: "blur(7px)",
  };

  const contentStyle = {
    paddingBottom: "100px",
  };

  return (
    <div className={Style.mainWrapper}>
      {data.showImg && <img src={leftBlackHole} alt="" />}
      <Container>
        {/* <MagicModal
          contentStyle={contentStyle}
          overlayStyle={overlayStyle}
          modalStyle={modalStyle}
          closeIcon={<IoCloseSharp className={Style.closeIcon} />}
          showCloseIcon={true}
          ref={modalRef}
        /> */}
        <div className={Style.row}>
          <div className={Style.left}>
            <h1>{data.title}</h1>
            <h6>{data.subTitle}</h6>
            {data.points.map((point, index) => {
              return <Point key={index} {...point} />;
            })}
            {/* <CustomButton
              style={{
                marginInline: width.width > 768 ? "" : "auto",
                marginTop: "25px",
              }}
              onClick={() => handleButtonClick()}
            >
              {data.btnText ? data.btnText : " Contact us Now"}
            </CustomButton> */}
          </div>
          <div
            className={Style.imgWrapper}
            style={{
              backgroundImage: `url(${data.picture})`,
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Point = ({ title }) => {
  return (
    <div className={Style.point}>
      <div className={Style.pointHead}>
        <img src={pinkCheck} alt="" />
        <h6>{title}</h6>
      </div>
    </div>
  );
};

export default CustomRow;
