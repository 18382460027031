import React from "react";

import BackVideo from "../../../Assets/video/cryptoGPTAnimation.mp4";

// Styles
import Style from "./VideoPlayer.module.css";

const VideoPlayer = () => {
  return (
    <video
      className={Style.video}
      loop={true}
      autoPlay={true}
      //   controls
      muted
    //   src={BackVideo}
    >
         <source src={BackVideo} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer;
