import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Components
import CustomButton from "../../../../Components/UIKit/CustomButton/CustomButton";
import TextSlider from "../TextSlider/TextSlider";

// Images
import bg from "../../../../Assets/images/aboutBG.png";
import aboutHeroBig from "../../../../Assets/images/aboutHeroBig.png";
import stars from "../../../../Assets/images/aboutStars.png";

// Styles
import Style from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <>
      <div className={Style.wrapper}>
        <img src={bg} alt="" />
        <img src={stars} alt="" />
        {/* <img src={circles} alt="" /> */}
        <Container>
          <div className={Style.heading}>
            <h1>
              About <span>Quantwise</span>
            </h1>
            <h4>
              Our goal is to empower clients to overcome market challenges,
              helping them transform their financial dreams into reality with
              confidence and wisdom.
            </h4>
            <CustomButton light>get started</CustomButton>
          </div>
          <div className={Style.heroImage}>
            <img src={aboutHeroBig} alt="" />
          </div>
        </Container>
      </div>
      <TextSlider />
      {/* <div className={Style.quant}></div> */}
    </>
  );
};

export default HeroSection;
