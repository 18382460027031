import React from "react";

// Libraries
import { Container, useWindowSize } from "@hybris-software/ui-kit";

// Images
import mentorImg from "../../../../Assets/images/tokenImage.png";
import sentImg from "../../../../Assets/images/bigGlobe.png";

// Styles
import Style from "./Products.module.css";
import CustomButton from "../../../../Components/UIKit/CustomButton/CustomButton";

// Data
const data = [
  {
    picture: mentorImg,
    title: "TokenMentor",
    text: "Unveil the secrets of the market with TokenMentor. This monthly membership provides valuable market insights, tips, and mid-to-long term investment suggestions with moderate risk. Stay informed, stay ahead.",
    to: "/token-mentor",
  },
  {
    picture: sentImg,
    title: "Sentinel X100",
    text: "Upgrade your investing game with Sentinel X100. This lifetime membership provides exclusive market signals and analysis. Get access to once-a-month reports, pinpointing tokens that could potentially yield from 30 to 100X in the next bull run. For the discerning investor who seeks the extraordinary.",
    to: "/sentinel",
  },
];

const Products = () => {
  return (
    <Container>
      <div className={Style.products}>
        <p>[&nbsp;&nbsp;&nbsp;&nbsp;Our Products&nbsp;&nbsp;&nbsp;&nbsp;]</p>
        <h1>What we’re working on</h1>
        <div className={Style.cards}>
          {data.map((item, index) => {
            return <Card keuy={index} index={index} {...item} />;
          })}
        </div>
      </div>
    </Container>
  );
};

const Card = ({ picture, title, text, to, index }) => {
  const width = useWindowSize();

  return (
    <div
      className={Style.cardWrapper}
      style={{
        marginTop: index === 1 && width.width > 991 ? "120px" : "0px",
      }}
    >
      <div className={Style.card}>
        <div
          style={{
            padding: index === 1 ? "40px" : "",
          }}
          className={Style.imgWrapper}
        >
          <div
            className={Style.cardImage}
            style={{
              backgroundImage: `url(${picture})`,
            }}
          ></div>
        </div>
        <div className={Style.cardBody}>
          <h2>{title}</h2>
          <h6>{text}</h6>
          <CustomButton
          // to={to}
          >
            Register Now
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default Products;
