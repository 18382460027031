import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Images
import toolsLeftBg from "../../../../Assets/images/toolsLeftBG.png";
import miniChip from "../../../../Assets/images/miniChip.png";
import analysisImg from "../../../../Assets/images/analysisImg.png";
import gptIcon from "../../../../Assets/images/gptIcon.png";
import results from "../../../../Assets/images/results.png";

// Styles
import Style from "./Tools.module.css";

const data = [
  {
    title: "Market Analysis",
    subTile:
      "Take advantage of our advanced market analysis to navigate the complex world of crypto and forex. Our algorithms sift through the noise to provide you with clear, actionable insights.",
    picture: analysisImg,
  },
  {
    title: "GPT Technology",
    subTile:
      "Experience the cutting-edge GPT technology that powers our market analysis. Our models interpret and anticipate market movements, providing you with a distinct trading advantage.",
    picture: gptIcon,
  },
  {
    title: "Institutional-Grade Results",
    subTile:
      "QuantWise's superior performance and reliability have caught the attention of institutional investors. Be part of the platform that's reshaping the trading landscape.",
    picture: results,
  },
];

const Tools = () => {
  return (
    <Container>
      <div className={Style.tools}>
        <img src={toolsLeftBg} alt="" />
        <div className={Style.wrapper}>
          <h1>Harness the Power of AI for Market Analysis and Results</h1>
          <div className={Style.infoGrid}>
            <div className={Style.right} />
            <div className={Style.left}>
              {data.map((item, index) => {
                return <Card key={index} {...item} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Card = ({ title, subTile, picture }) => {
  return (
    <div className={Style.card}>
      <img src={picture} alt="" />
      <div className={Style.headings}>
        <h2>{title}</h2>
        <h6>{subTile}</h6>
      </div>
    </div>
  );
};

export default Tools;
