import React from "react";

// Components
import BeSmarter from "../../Components/UIKit/BeSmarter/BeSmarter";
import Power from "./Components/Power/Power";
import Creators from "../../Components/UIKit/Creators/Creators";
import Tools from "./Components/Tools/Tools";
import Empower from "./Components/Empower/Empower";
import HeroSection from "./Components/HeroSection/HeroSection";

// Styles
import Style from "./OurAIView.module.css";

const OurAIView = () => {
  return (
    <>
      <section>
        <HeroSection />
      </section>
      <section className={Style.power}>
        <Power />
      </section>
      <section>
        <Creators
          title={"AI made for your Goal."}
          subTitle={
            "Navigate the markets with confidence, wisdom, and the power of artificial intelligence."
          }
        />
      </section>
      <section>
        <Tools />
      </section>
      <section>
        <Empower />
      </section>
      <section>
        <BeSmarter />
      </section>
    </>
  );
};

export default OurAIView;
