// Components
import NavBar from "../../Components/UIKit/NavBar/NavBar";
import Footer from "../../Components/UIKit/Footer/Footer";

// Libraries
import { Outlet } from "react-router-dom";

// Styles
import Style from "./MainLayout.module.css";

const MainLayout = () => {
  return (
    <main>
      <NavBar />
      <Outlet />
      <Footer />
    </main>
  );
};

export default MainLayout;
