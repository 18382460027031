import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Images
import accImage from "../../../../Assets/images/accImage.png";
import discoBoxes from "../../../../Assets/images/discoBoxes.png";
import portfolioImg from "../../../../Assets/images/portfolioImg.png";
import subscriptionImg from "../../../../Assets/images/subscriptionImg.png";
import watchPortfolioImage from "../../../../Assets/images/watchPortfolioImage.png";

// Styles
import Style from "./HowItWorks.module.css";

const data = [
  {
    picture: accImage,
    title: "Create an Account",
    subTitle:
      "Kickstart your journey by setting up a QuantWise account in minutes.",
  },
  {
    picture: subscriptionImg,
    title: "Choose your Subscription",
    subTitle:
      "Pick a CryptoGPT subscription that aligns with your investment goals.",
  },
  {
    picture: portfolioImg,
    title: "Watch Your Portfolio Grow",
    subTitle:
      "Sit back, relax, and watch your crypto portfolio flourish with our AI-powered trading.",
  },
];

const HowItWorks = () => {
  return (
    <div className={Style.how}>
      <Container>
        <div className={Style.heading}>
          <h1>Discover Smarter Trading with CryptoGPT</h1>
          <h6>
            Experience profitable crypto trading in just three simple steps.
          </h6>
        </div>
        <div className={Style.cards}>
          {data.map((item, index) => {
            return <Card key={index} {...item} />;
          })}
        </div>
      </Container>
    </div>
  );
};

const Card = ({ picture, title, subTitle }) => {
  return (
    <div className={Style.card}>
      <div
        className={Style.cardHead}
      >
        <img src={picture} alt="" />
      </div>
      <div className={Style.cardFoot}>
        <h3>{title}</h3>
        <h6>{subTitle}</h6>
      </div>
    </div>
  );
};

export default HowItWorks;
