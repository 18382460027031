import { Route } from "react-router-dom";

// Icons
import { AiFillHome } from "react-icons/ai";

// Layouts
import MainLayout from "../Layouts/MainLayout/MainLayout";

// Main Views
import HomePageView from "../Views/HomePageView";
import AboutUsView from "../Views/AboutUsView";
import CryptoGPTView from "../Views/CryptoGPTView";
import OurAIView from "../Views/OurAIView";
import StrategyDetailsView from "../Views/StrategyDetailsView";
import ProductsView from "../Views/ProductsView";
import StrategyView from "../Views/StrategyView";
import ForexGPTView from "../Views/ForexGPTView";
import SentinelView from "../Views/SentinelView";
import TokenMentorView from "../Views/TokenMentorView";
import BlogsView from "../Views/BlogsView";
import BlogDetailsView from "../Views/BlogDetailsView";
import PricingView from "../Views/PricingView";
import ContactUsView from "../Views/ContactUsView";
import InstitutionalView from "../Views/InstitutionalView";

const routes = {
  main: {
    path: "/",
    element: <MainLayout />,
    title: "Insert title",
    routes: {
      home: {
        path: "",
        element: <HomePageView />,
        title: "Home",
        icon: <AiFillHome />,
      },
      aboutUs: {
        path: "about-us",
        element: <AboutUsView />,
        title: "About Us",
        icon: <AiFillHome />,
      },
      cryptoGPT: {
        path: "crypto-gpt",
        element: <CryptoGPTView />,
        title: "Crypto GPT",
        icon: <AiFillHome />,
      },
      ourAI: {
        path: "our-ai",
        element: <OurAIView />,
        title: "Our AI",
        icon: <AiFillHome />,
      },
      // strategyDetail: {
      //   path: "strategies/:id",
      //   element: <StrategyDetailsView />,
      //   title: "Strategy Details",
      //   icon: <AiFillHome />,
      // },
      products: {
        path: "products",
        element: <ProductsView />,
        title: "Our Products",
        icon: <AiFillHome />,
      },
      // forexGpt: {
      //   path: "forex-gpt",
      //   element: <ForexGPTView />,
      //   title: "Forex GPT",
      //   icon: <AiFillHome />,
      // },
      // strategies: {
      //   path: "strategies",
      //   element: <StrategyView />,
      //   title: "Strategies",
      //   icon: <AiFillHome />,
      // },
      // sentinel: {
      //   path: "sentinel",
      //   element: <SentinelView />,
      //   title: "Sentinel",
      //   icon: <AiFillHome />,
      // },
      // tokenMentor: {
      //   path: "token-mentor",
      //   element: <TokenMentorView />,
      //   title: "Token Mentor",
      //   icon: <AiFillHome />,
      // },
      // blogs: {
      //   path: "blogs",
      //   element: <BlogsView />,
      //   title: "Blogs",
      //   icon: <AiFillHome />,
      // },
      // blogDetails: {
      //   path: "blogs/:id",
      //   element: <BlogDetailsView />,
      //   title: "Blogs",
      //   icon: <AiFillHome />,
      // },
      // pricing: {
      //   path: "pricing",
      //   element: <PricingView />,
      //   title: "Pricing",
      //   icon: <AiFillHome />,
      // },
      // contactUs: {
      //   path: "contact-us",
      //   element: <ContactUsView />,
      //   title: "Contact us",
      //   icon: <AiFillHome />,
      // },
      institutional: {
        path: "institutional",
        element: <InstitutionalView />,
        title: "Institutional",
        icon: <AiFillHome />,
      },
    },
  },
};

// function generatePaths(routes, parentPath = "") {
//   const paths = {};
//   Object.entries(routes).forEach(([routeName, route]) => {
//     if (route.routes) {
//       paths[routeName] = generatePaths(route.routes, parentPath + route.path);
//     } else {
//       paths[routeName] = parentPath + route.path;
//     }
//   });
//   return paths;
// }

function generatePaths(routes, parentPath = "") {
  const paths = {};
  Object.entries(routes).forEach(([routeName, route]) => {
    if (route.routes && Object.keys(route.routes).length > 0) {
      paths[routeName] = generatePaths(route.routes, parentPath + route.path);
    } else {
      paths[routeName] = parentPath + route.path;
    }
  });
  return paths;
}

function generateRouteComponents(routes, parentPath = "") {
  if (parentPath === "") {
    // Is a layout
    return Object.keys(routes).map((routeName, index) => {
      return (
        <Route key={index} element={routes[routeName].element}>
          {generateRouteComponents(
            routes[routeName].routes,
            routes[routeName].path
          )}
        </Route>
      );
    });
  } else {
    // Is a view
    const views = [];

    Object.keys(routes).forEach((routeName, index) => {
      views.push(
        <Route
          key={index}
          path={parentPath + routes[routeName].path}
          element={routes[routeName].element}
        />
      );

      if (routes[routeName].routes) {
        views.push(
          generateRouteComponents(
            routes[routeName].routes,
            parentPath + routes[routeName].path
          )
        );
      }
    });
    return views;
  }
}

function generateSitemap(routes, parentPath = "") {
  return (
    <ol style={{ marginLeft: "2em" }}>
      {Object.keys(routes).map((routeName, index) => {
        return (
          <li key={index}>
            {parentPath === "" ? (
              routes[routeName].title
            ) : (
              <a href={parentPath + routes[routeName].path}>
                {routes[routeName].title} ({parentPath + routes[routeName].path}
                )
              </a>
            )}

            {routes[routeName].routes &&
              generateSitemap(
                routes[routeName].routes,
                parentPath + routes[routeName].path
              )}
          </li>
        );
      })}
    </ol>
  );
}

const paths = generatePaths(routes);
const routeComponents = generateRouteComponents(routes);
const sitemap = generateSitemap(routes);

export { routes, paths, routeComponents, sitemap };
