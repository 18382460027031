import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Components
import CustomButton from "../CustomButton/CustomButton";

// Paths
import { paths } from "../../../Data/routes";

// Images
import rightBoxes from "../../../Assets/images/rightBoxes.png";
import joinGradient from "../../../Assets/images/joinGradient.png";
import Union from "../../../Assets/icons/Union.svg";
import threeDots from "../../../Assets/images/threeDots.png";
import twoCircles from "../../../Assets/images/twoCircles.png";

// Styles
import Style from "./Join.module.css";

const Join = () => {
  return (
    <Container>
      <div className={Style.mainWrapper}>
        <div className={Style.joinWrapper}>
          {/* <img src={joinGradient} alt="" /> */}
          <img className={Style.topLeft} src={Union} alt="" />
          <img className={Style.topRight} src={Union} alt="" />
          <img className={Style.bottomLeft} src={Union} alt="" />
          <img className={Style.bottomRight} src={Union} alt="" />
          <img className={Style.threeDots} src={threeDots} alt="" />
          <img src={twoCircles} className={Style.twoCircles} alt="" />
          <div className={Style.join}>
            <img src={rightBoxes} alt="" />
            <h1>Join the Institutional revolution with Quantwise</h1>
            <h6>
              Get started with Quantwise - AI today and experience the power of
              AI in your portfolio!
            </h6>
            <CustomButton to={paths.main.institutional}>
              Visit institutional
            </CustomButton>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Join;
