import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Components
import CustomButton from "../../../../Components/UIKit/CustomButton/CustomButton";

// Icons
import Union from "../../../../Assets/icons/Union.svg";

// Styles
import Style from "./Awesome.module.css";

const data = [
  {
    title: "Exclusive Information",
    text: "Gain access to top-tier, proprietary investment insights not found elsewhere.",
  },
  {
    title: "AI Analysis and Expert Insights",
    text: "Benefit from a unique blend of cutting-edge AI analysis and global expert input.",
  },
  {
    title: "Significant Infrastructure ",
    text: "We invest over $5M annually to ensure comprehensive and high-quality reports.",
  },
  {
    title: "Trusted by Top-Tier Investors",
    text: "Venture Capitals and Super Investment Funds utilize our reports.",
  },
];

const Awesome = () => {
  return (
    <Container>
      <div className={Style.awesome}>
        <img className={Style.topLeft} src={Union} alt="" />
        <img className={Style.topRight} src={Union} alt="" />
        <img className={Style.bottomLeft} src={Union} alt="" />
        <img className={Style.bottomRight} src={Union} alt="" />
        <div className={Style.left}>
          <p>[&nbsp;&nbsp;&nbsp;&nbsp;Features&nbsp;&nbsp;&nbsp;&nbsp;]</p>
          <h2>Unlock Valuable Information with our Report</h2>
          <h6>
            Experience the power of actionable market insights with our premium
            TokenMentor and Sentinel X100 reports. Tailored for discerning
            investors, these reports offer exclusive tips and potential
            investment areas for superior portfolio performance.
          </h6>
          <CustomButton>access now</CustomButton>
        </div>
        <div className={Style.right}>
          {data.map((item, index) => {
            return (
              <div className={Style.card}>
                <h4>0{index + 1}.</h4>
                <h4>{item.title}</h4>
                <h6>{item.text}</h6>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default Awesome;
