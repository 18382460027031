import React, { useState } from "react";

// Libraries
import { useLocation } from "react-router-dom";
import { useWindowSize, Container } from "@hybris-software/ui-kit";
import { Link } from "react-router-dom";

// Components
import CustomButton from "../CustomButton/CustomButton";

// Routes
import { paths } from "../../../Data/routes";

// Icons
import { IoIosArrowDown } from "react-icons/io";

// Images
import Logo from "../../../Assets/icons/fullLogo.svg";

// Styles
import Style from "./NavBar.module.css";
import classNames from "../../../Utils/classNames";

const Navbar = () => {
  const { width } = useWindowSize();
  const [menuOpen, setMenuOpen] = useState(false);

  const aboutLinksData = [
    {
      title: "About Us",
      link: "/about-us",
    },
    // {
    //   title: "Blog",
    //   link: "/blogs",
    // },
    // {
    //   title: "Contact Us",
    //   link: "/contact-us",
    // },
  ];
  const productLinksData = [
    {
      title: "Our Products",
      link: "/products",
    },
    {
      title: "Sentinel",
      link: "/sentinel",
    },
    {
      title: "Token Mentor",
      link: "/token-mentor",
    },
  ];
  const aiLinksData = [
    {
      title: "AI",
      link: "/our-ai",
    },
    {
      title: "Crypto GPT",
      link: "/crypto-gpt",
    },
    // {
    //   title: "Forex GPT",
    //   link: "/forex-gpt",
    // },
  ];

  const navData = [
    { title: "home", path: "/" },
    { title: "Products", path: "/products" },
    { title: "Our ai", path: "/our-ai", links: aiLinksData },
    { title: "Institutional", path: "/institutional" },
    // { title: "Strategy", path: "/strategies" },
    // { title: "pricing", path: "/pricing" },
    {
      title: "About",
      path: "/about-us",
      // , links: aboutLinksData
    },
  ];

  const location = useLocation();

  if (width > 1440) if (menuOpen) setMenuOpen(false);

  if (menuOpen) document.body.style.overflow = "hidden";
  else document.body.style.overflow = "unset";

  return (
    <div className={Style.rap}>
      <header className={Style.header}>
        <div className={width > 1440 ? Style.navbar : Style.navbarMobile}>
          <Link to={paths.main.home}>
            <img className={Style.logo} src={Logo} alt="Logo" />
          </Link>
          <nav
            className={
              width > 1440
                ? Style.navigator
                : menuOpen
                ? Style.navigator
                : Style.navigatorClose
            }
          >
            <ul className={Style.menuList}>
              {navData.map((item, index) => {
                return (
                  <LinksDropDown
                    key={index}
                    {...item}
                    setMenuOpen={setMenuOpen}
                  />
                );
              })}

              <li
                style={{
                  cursor: "not-allowed",
                }}
              >
                <Link
                  onClick={() => {
                    setMenuOpen((old) => !old);
                    document.documentElement.scrollTop = 0;
                  }}
                  // className={
                  //   location.pathname === paths.main.strategies
                  //     ? Style.pageActive
                  //     : ""
                  // }
                  style={{
                    pointerEvents: "none",
                    color: "#FFFFFF",
                  }}
                  className={Style.login}
                  to={paths.main.strategies}
                >
                  LogIn
                </Link>
              </li>

              <li>
                <CustomButton>Register Now</CustomButton>
              </li>
            </ul>
          </nav>

          <div
            className={menuOpen ? Style.hamburgerActive : Style.hamburger}
            onClick={() => {
              setMenuOpen((old) => !old);
              document.documentElement.scrollTop = 0;
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </header>
    </div>
  );
};

const LinksDropDown = ({ title, links, path, setMenuOpen }) => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  // const checkLink = (links) => {
  //   if (
  //     links?.forEach((link) => {
  //       link == location.pathname;
  //     })
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  if (links) {
    return (
      <li
        onMouseLeave={() => {
          setActive(false);
        }}
        className={Style.productsWrapper}
      >
        <div
          onClick={() => {
            setActive(!active);
            // setMenuOpen((old) => !old);
            // document.documentElement.scrollTop = 0;
          }}
          to={path}
          className={
            location.pathname === path || links?.some((link) => link.link === location.pathname)
              ? Style.pageActive
              : Style.page
          }
        >
          {title}{" "}
          <IoIosArrowDown
            className={active ? Style.dropDowIconActive : Style.dropDownIcon}
          />
        </div>
        <div className={active ? Style.productLinksActive : Style.productLinks}>
          {links.map((item, index) => {
            return (
              <div
                key={index}
                className={classNames(
                  Style.productLink
                  // item.comingSoon && Style.tooltip
                )}
              >
                <Link
                  onClick={() => {
                    setMenuOpen((old) => !old);
                    document.documentElement.scrollTop = 0;
                  }}
                  // style={{
                  //   pointerEvents: item.comingSoon ? "none" : "",
                  // }}

                  className={
                    location.pathname === item.link ? Style.pageActive : ""
                  }
                  to={item.link}
                >
                  {item.title}
                </Link>
                {/* {item.comingSoon && (
                  <span className={Style.tooltiptext}>Coming soon!</span>
                )} */}
              </div>
            );
          })}
        </div>
      </li>
    );
  } else {
    return (
      <li>
        <Link
          onClick={() => {
            setMenuOpen((old) => !old);
            document.documentElement.scrollTop = 0;
          }}
          className={location.pathname === path ? Style.pageActive : ""}
          to={path}
        >
          {title}
        </Link>
      </li>
    );
  }
};

export default Navbar;
