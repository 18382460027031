import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Images
import empowerBGgrad from "../../../../Assets/images/empowerBGgrad.png";

// Styles
import Style from "./Empower.module.css";

const data = [
  {
    title: "User-friendly",
    text: "Effortlessly navigate the crypto and forex markets with our easy-to-use platform.",
  },
  {
    title: "Fully Automated",
    text: "Our AI takes over trading complexities, ensuring seamless automation of your strategies.",
  },
  {
    title: "Binance Broker Partnership",
    text: "Ensure secure management of your wallets through our partnership with Binance Broker Link.",
  },
  {
    title: "Customized GPT Technology",
    text: "Experience the power of our tailored GPT technology, designed to cater to your trading needs.",
  },
  {
    title: "Extensive Testing",
    text: "Benefit from our robust strategy, backed by over 15.000 hours of testing and running.",
  },
  {
    title: "Happy User Base",
    text: "Join over 20,000 satisfied users experiencing the transformative power of our AI technology.",
  },
];

const Empower = () => {
  return (
    <div className={Style.empower}>
      <img src={empowerBGgrad} alt="" />
      <Container>
        <div className={Style.head}>
          <h1>
            Empowering crypto enthusiasts with AI tools and expert resources.
          </h1>
          <div className={Style.globe} />
        </div>
        <div className={Style.powerGrid}>
          {data.map((item, index) => {
            return (
              <div key={index} className={Style.card}>
                <h4>0{index + 1}.</h4>
                <h4>{item.title}</h4>
                <h6>{item.text}</h6>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default Empower;
