import React from "react";

// Libraries
import { Container, useWindowSize } from "@hybris-software/ui-kit";

// Components
import CustomButton from "../../../../Components/UIKit/CustomButton/CustomButton";
import VideoPlayer from "../../../../Components/Advanced/VideoPlayer/VideoPlayer";

// Images
import spiralTank from "../../../../Assets/images/spiralTank.png";

// Styles
import Style from "./HeroSection.module.css";

const HeroSection = () => {
  const width = useWindowSize();
  return (
    <div className={Style.hero}>
      <Container>
        <div className={Style.heroGrid}>
          <div className={Style.titles}>
            <h1>Everything is possible with CryptoGPT</h1>
            <h4>
              Harness the power of advanced AI to transform your crypto trading
              journey. With CryptoGPT, experience seamless automation, robust
              strategies, and unrivaled market insights. Embark on a successful
              trading adventure where everything is indeed possible. 
            </h4>
            <CustomButton light>get started</CustomButton>
          </div>
          {/* <div className={Style.images}>
            <img src={spiralTank} alt="" />
            <div className={Style.codePalet}></div>
          </div> */}
          <VideoPlayer />
        </div>
      </Container>
    </div>
  );
};

export default HeroSection;
