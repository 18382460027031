// Libraries
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { generateApiClient, ApiProvider } from "@hybris-software/use-query";
// import { AuthProvider } from "@hybris-software/use-auth";
import { ThemeProvider } from "@hybris-software/ui-kit";

// Components
import LayoutRouter from "./Components/Advanced/Router/LayoutRouter";
import Loader from "./Components/Advanced/Loader/Loader";

// Data
import config from "./Data/config";
import { routeComponents } from "./Data/routes";
import theme from "./Data/theme";

// Views
import NotFoundView from "../src/Views/NotFoundView/NotFoundView";

function App() {
  const apiClient = generateApiClient({
    baseUrl: config.API_BASE_URL,
    authorizationHeader: "Authorization",
    authorizationPrefix: "Token ",
  });

  const authUrl = `${config.API_BASE_URL}/auth`;

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <LayoutRouter
          loader={<Loader />}
          loadingTime={1500}
          clientTitle="QUANTWISE"
        >
          <ApiProvider apiClient={apiClient}>
            {/* <AuthProvider apiClient={apiClient} authUrl={authUrl}> */}
            <Routes>
              {routeComponents}
              <Route path="*" element={<NotFoundView />} />
            </Routes>
            {/* </AuthProvider> */}
          </ApiProvider>
        </LayoutRouter>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
