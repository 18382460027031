import React from "react";

import { Container } from "@hybris-software/ui-kit";

// Components
import CustomButton from "../CustomButton/CustomButton";

// Styles
import Style from "./Smart.module.css";

const Smart = ({ slider }) => {
  return (
    <Container>
      {/* // <div className={Style.partWrapper}>  */}
      <div className={Style.partOne}>
        <h1>
          Be part of the future of
          <span> Quantwise</span>
        </h1>
        <h4>
          Get started with Quantwise - AI today and experience the power of AI
          in your portfolio!
        </h4>
        <CustomButton light>Get started</CustomButton>
      </div>
      {/* // </div>  */}
    </Container>
  );
};

export default Smart;
