import React from "react";

// Components
import HeroSection from "./Components/HeroSection/HeroSection";
import { PartnersSlider } from "../../Components/UIKit/PartnersSlider/PartnersSlider";
import Creators from "../../Components/UIKit/Creators/Creators";
import Payments from "../../Components/UIKit/Payments/Payments";
import Join from "../../Components/UIKit/Join/Join";
import BeSmarter from "../../Components/UIKit/BeSmarter/BeSmarter";
import Smart from "../../Components/UIKit/Smart/Smart";
import CryptoSliders from "../../Components/UIKit/CryptoSliders/CryptoSliders";

// Styles
import Style from "./HomePageView.module.css";

const HomePageView = () => {
  return (
    <div>
      <section className={Style.hero}>
        <HeroSection />
        <div className={Style.cryptoSlider}>
          <CryptoSliders />
        </div>
      </section>
      <section className={Style.slider}>
        <PartnersSlider />
      </section>
      <section className={Style.creators}>
        <Creators
          title={"Discover Our AI"}
          subTitle={"Quantwise unlocks the potential of AI for your goals."}
        />
      </section>
      <section>
        <Payments />
      </section>

      <section className={Style.join}>
        <Join />
      </section>
      <section className={Style.smart}>
        <BeSmarter slider />
      </section>
    </div>
  );
};

export default HomePageView;
