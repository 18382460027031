import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Components
import CustomButton from "../../../../Components/UIKit/CustomButton/CustomButton";
import CryptoSliders from "../../../../Components/UIKit/CryptoSliders/CryptoSliders";

// Images
import heroEllipse from "../../../../Assets/images/eclipseRight.png";
import heroEllipseLeft from "../../../../Assets/images/eclipseLeft.png";

// Styles
import Style from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <>
      <div className={Style.heroWrapper}>
        <div className={Style.wrapper}>
          <img src={heroEllipse} alt="" />
          <img src={heroEllipseLeft} alt="" />
          <div className={Style.ellipseLeft} />
          <Container>
            <div className={Style.heading}>
              <h1>
                Trading is Difficult?
                <br />
                Not with CryptoGPT and {" "}
                <span> Quantwise</span>
              </h1>
              <h4>
                The all-in-one crypto platform. Learn, trade, and stay ahead
                with AI-powered tools, expert training, market updates, and
                industry reports.
              </h4>
              <CustomButton light>get started</CustomButton>
            </div>
          </Container>
          {/* <div className={Style.lorem}>
            <CryptoSliders />
          </div> */}
        </div>
      </div>

      <div className={Style.iframeWrapper}>
        <div className={Style.iframe} />
      </div>
    </>
  );
};

export default HeroSection;
