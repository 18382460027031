import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { Link } from "react-router-dom";

// Components
import PriceCard from "../PriceCard/PriceCard";

// Images
import connectLinesRight from "../../../Assets/images/connectLinesRight.png";
import stars from "../../../Assets/images/stars.png";
import blueMoon from "../../../Assets/images/blueMoon.png";
import bloodMoon from "../../../Assets/images/bloodMoon.png";
import purpleMoon from "../../../Assets/images/purpleMoon.png";

// Styles
import Style from "./Payments.module.css";
import { paths } from "../../../Data/routes";

const data = [
  {
    title: "PLUS",
    subTitle: "Our CryptoGPT at your service with:",
    price: "50%",
    currency: "",
    discount: "",
    lightBtn: true,
    btnText: "get started",
    list: [
      {
        text: "50% Performance Fee based on the profit made by the AI",
      },
      {
        text: "Access to Strategy Storic",
      },
      {
        text: "Ability to explore the app and its features without any cost",
      },
    ],
  },
  {
    title: "PRO",
    subTitle: "Our CryptoGPT at your service with:",
    price: "40%",
    currency: "",
    discount: "",
    lightBtn: true,
    btnText: "get started",

    list: [
      { text: "42% Performance Fee based on the profit made by the AI" },
      { text: "An analytics dashboard to track your trades" },
      { text: "Priority support to solve issues quickly" },
    ],
  },
  {
    title: "Expert",
    subTitle: "Our CryptoGPT at your service with:",
    price: "30%",
    currency: "",
    discount: "",
    lightBtn: false,
    btnText: "contact us",
    list: [
      { text: "You keep 70% of the profit from the Strategy" },
      { text: "Exclusive Performance Strategy Unlocked" },
      { text: "VIP Support" },
    ],
  },
];

const Payments = () => {
  return (
    <div className={Style.payments}>
      <div className={Style.heading}>
        <img src={stars} alt="stars" />
        <img src={blueMoon} alt="Bluemoon" />
        <img src={bloodMoon} alt="bllodmoon" />
        <img src={purpleMoon} alt="purpleMoon" />
        <img src={bloodMoon} alt="bloodMoon" />
        <p>
          {" "}
          [&nbsp;&nbsp;&nbsp;&nbsp; Get started with QUANTWISE
          &nbsp;&nbsp;&nbsp;&nbsp;]
        </p>
        <h1>Discover the Power of our AI</h1>
      </div>
      <Container>
        <div className={Style.cards}>
          <img src={connectLinesRight} alt="" />
          <img src={connectLinesRight} alt="" />
          {data.map((item, index) => {
            return <PriceCard key={index} index={index} {...item} />;
          })}
        </div>
      </Container>
      {/* <Link to={paths.main.pricing} className={Style.seeDetails}>
        see the full details
      </Link> */}
    </div>
  );
};

export default Payments;
