import React from "react";

// Icons
import Union from "../../../../Assets/icons/Union.svg";
import fiveStars from "../../../../Assets/images/experienceImg.png";
import threecircles from "../../../../Assets/images/transparencyImg.png";
import protection from "../../../../Assets/images/securityImg.png";

// Styles
import Style from "./Success.module.css";
import { Container } from "@hybris-software/ui-kit";

const data = [
  {
    title: "Transparency",
    subTitle:
      "Ensuring an open approach with clear, accountable trading actions.",
    picture: threecircles,
  },
  {
    title: "Experience Team ",
    subTitle:
      "Rely on a seasoned team with in-depth knowledge of the trading landscape.",
    picture: fiveStars,
  },
  {
    title: "Security ",
    subTitle:
      "Prioritizing your funds' safety with high-grade, secure trading systems.",
    picture: protection,
  },
];

const Success = () => {
  return (
    <Container>
      <div className={Style.hero}>
        <img className={Style.topLeft} src={Union} alt="" />
        <img className={Style.topRight} src={Union} alt="" />
        <img className={Style.bottomLeft} src={Union} alt="" />
        <img className={Style.bottomRight} src={Union} alt="" />
        <div className={Style.topGrid}>
          <div>
            <p>[&nbsp;&nbsp;&nbsp;&nbsp;Our Mission&nbsp;&nbsp;&nbsp;]</p>
            <h1>We are helping people to get a success.</h1>
          </div>
          <h6>
            We believe in the potential of each individual to achieve financial
            freedom. We understand the obstacles and challenges they've faced in
            the past, and our aim is to equip them with the right tools,
            knowledge, and support to overcome those barriers and succeed in
            their financial journey.
          </h6>
        </div>
        <div className={Style.bottomGrid}>
          {data.map((item, index) => {
            return <Card key={index} {...item} />;
          })}
        </div>
      </div>
    </Container>
  );
};

const Card = ({ picture, title, subTitle }) => {
  return (
    <div className={Style.card}>
      <img src={picture} alt="" />
      <h4>{title}</h4>
      <h6>{subTitle}</h6>
    </div>
  );
};

export default Success;
