import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Icons
import aiIcon from "../../../../Assets/icons/aiMiniIcon.svg";
import doolarSwitvch from "../../../../Assets/icons/doolarSwitvch.svg";
import blueShield from "../../../../Assets/images/blueShield.png";
import basedIng from "../../../../Assets/images/basedIng.png";
import testingImg from "../../../../Assets/images/testingImg.png";
import abilityImg from "../../../../Assets/images/abilityImg.png";
import secImg from "../../../../Assets/images/secImg.png";
import performImg from "../../../../Assets/images/performImg.png";
import trackingImg from "../../../../Assets/images/trackingImg.png";

// Images
import purpleFeatBG from "../../../../Assets/images/purpleFeatBG.png";

// Styles
import Style from "./Features.module.css";

const data = [
  {
    picture: basedIng,
    title: "GPT-7 Based AI",
    subTitle:
      "Advanced GPT-7 technology for highly precise trading insights.This cutting-edge AI helps spot market trends and seize profitable opportunities.",
  },
  {
    picture: testingImg,
    title: "Extensive Testing",
    subTitle:
      "Over 17,000 hours of rigorous testing for optimized performance. This exhaustive test phase ensures reliable performance and minimal downtime.",
  },
  {
    picture: abilityImg,
    title: "High Scalability",
    subTitle:
      "Infrastructure designed for effortless scalability as your portfolio grows. Whether you're a trader or whale, CryptoGPT effortlessly scales with you.",
  },
  {
    picture: secImg,
    title: "Premium Security",
    subTitle:
      "Prioritizing your funds and data with industry-leading security measures. We leverage state-of-the-art encryption and compliance practices.",
  },
  {
    picture: performImg,
    title: "Documented Performance",
    subTitle:
      "Verifiable performance records of consistent success over 36 months. Our impressive track record, verified by trusted third parties, speaks about us.",
  },
  {
    picture: trackingImg,
    title: "Whale Tracking",
    subTitle:
      "Monitored over 7,000 market whales' activities for data-rich insights. This  tracking offers invaluable data, informing strategies and improving.",
  },
];

const Features = () => {
  return (
    <div className={Style.features}>
      <img src={purpleFeatBG} alt="" />
      <Container>
        <div className={Style.title}>
          <h1>Powering Your Trades with Advanced Technology</h1>
          <h6>
            Harness the prowess of CryptoGPT's features, backed by rigorous
            testing, market insights, and top-notch security for a truly
            optimized trading experience.
          </h6>
        </div>
        <div className={Style.cards}>
          {data.map((item, index) => {
            return <Card key={index} {...item} />;
          })}
        </div>
      </Container>
    </div>
  );
};

const Card = ({ picture, title, subTitle }) => {
  return (
    <div className={Style.card}>
      <img src={picture} alt="" />
      <h4>{title}</h4>
      <h6>{subTitle}</h6>
    </div>
  );
};

export default Features;
