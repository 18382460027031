import cake from "../../../Assets/images/joinImg.png";

export const options = {
  title: "Join Our Growing Community",
  picture: cake,
  data: [
    {
      title: "Over 20,000 registered users",
    },
    {
      title: "Trust Reaffirmed",
    },
    {
      title: "New Product Coming Soon",
    },
  ],
};
