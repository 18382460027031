import React from "react";

// Components
import { Container, SimpleSlider } from "@hybris-software/ui-kit";

// Libraries
import { useLocation } from "react-router-dom";

// Icons
import technoLogo from "../../../Assets/icons/technoLogo.svg";
import protocol33Logo from "../../../Assets/icons/protocol33Logo.svg";
import okexLogo from "../../../Assets/icons/okexLogo.png";
import binanceLogo from "../../../Assets/icons/binanceLogo.png";
import BlockChain from "../../../Assets/images/BLOCKCHAIN.png";
import LFI from "../../../Assets/images/LFI.svg";
import accessLogo from "../../../Assets/icons/2accessLogo.svg";
import kmallLogo from "../../../Assets/icons/kmallLogo.svg"

// Style
import Style from "./PartnersSlider.module.css";

const data = [
  {
    src: okexLogo,
  },
  {
    src: binanceLogo,
  },
  {
    src: accessLogo,
  },
  {
    src: kmallLogo,
  },
];

export const PartnersSlider = ({ messageRef }) => {
  return (
    <Container>
      <div className={Style.partnerSlide}>
        <p>Our partner and supported Exchange</p>
        <SimpleSlider
          paginationButtons={false}
          autoPlay={true}
          autoPlaySpeed={3500}
          breakPoints={{
            1200: { slidesPerView: 3 },
            992: { slidesPerView: 2 },
            558: { slidesPerView: 1 },
          }}
        >
          {data?.map((data, id) => {
            return (
              <div className={Style.logoPartner} key={id}>
                <img src={data.src} alt="partners" />
              </div>
            );
          })}
        </SimpleSlider>
      </div>
    </Container>
  );
};
