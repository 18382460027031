import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Icons
import chat from "../../../../Assets/images/solutionImg.png";
import plug from "../../../../Assets/images/integrationImg.png";
import resize from "../../../../Assets/images/supportImg.png";

// Styles
import Style from "./Success.module.css";

// Data
const data = [
  {
    icon: chat,
    title: "Tailored Solutions",
    text: "AI models custom-fitted to meet unique institutional requirements, optimizing trading performance.",
  },
  {
    title: "Seamless Integration",
    text: "Easy adoption with existing infrastructure, offering an enhanced trading experience without disruption.",
    icon: plug,
  },
  {
    icon: resize,
    title: "Unrivaled Support",
    text: "Dedicated team support ensuring smooth operation and addressing your institutional needs round the clock.",
  },
];

const Success = () => {
  return (
    <Container>
      <div className={Style.success}>
        <p>[&nbsp;&nbsp;&nbsp;INSTITUTIONAL&nbsp;&nbsp;&nbsp;]</p>
        <h1>Partner with the Power of AI</h1>
        <h6>
          Elevate your institutional trading capabilities with our robust,
          AI-driven trading strategies.
        </h6>
        <div className={Style.infoGrid}>
          {data.map((item, index) => {
            return <Card key={index} {...item} />;
          })}
        </div>
      </div>
    </Container>
  );
};

const Card = ({ icon, title, text }) => {
  return (
    <div className={Style.card}>
      <img src={icon} alt="" />
      <h3>{title}</h3>
      <h6>{text}</h6>
    </div>
  );
};

export default Success;
