import React, { useState } from "react";

// Libraries
import { Container, InputField, useWindowSize } from "@hybris-software/ui-kit";
import { Link } from "react-router-dom";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

// Paths
import { paths } from "../../../Data/routes";

// Icons
import logo from "../../../Assets/icons/fullLogo.svg";
import twitter from "../../../Assets/icons/twitter.svg";
import telegram from "../../../Assets/icons/telegram.svg";
import facebook from "../../../Assets/icons/facebook.svg";
import { FaXTwitter } from "react-icons/fa6";
import { BsFillCheckSquareFill, BsYoutube, BsInstagram } from "react-icons/bs";
import { SiCrunchbase, SiMedium } from "react-icons/si";
import { FaMedium } from "react-icons/fa";

// Styles
import Style from "./Footer.module.css";
import CustomButton from "../CustomButton/CustomButton";

const links = {
  data: [
    {
      internal: true,
      value: "Our Products",
      link: "products",
    },
    {
      internal: true,
      value: "Our AI",
      link: "our-ai",
    },
    // { value: "PRICING", link: "pricing" },
    {
      internal: true,
      value: "INSTITUTIONAL",
      link: "institutional",
    },
    // { value: "BLOG", link: "blogs" },
    // { value: "CONTACT US", link: "contact-us" },
  ],
  header: "QUCIK lINKS",
};

const resources = {
  data: [
    {
      internal: true,
      value: "ABOUT US",
      link: "about-us",
    },

    { internal: false, value: "Docs", link: "https://docs.quantwise.ai/" },
    {
      internal: false,
      value: "Privacy & Cookie Policy",
      link: "https://docs.quantwise.ai/terms/privacy-and-cookies",
    },
    {
      internal: false,
      value: "Terms of use",
      link: "https://docs.quantwise.ai/terms/terms-of-use",
    },

    // { value: "Privacy", link: "" },
    // { value: "risk disclosure", link: "" },
  ],
  header: "resources",
};

const Footer = () => {
  const [check, setCheck] = useState(false);
  const [message, setMessage] = useState(false);
  const contactForm = useForm({
    inputs: {
      email: {
        required: true,
        nature: "email",
      },
    },
  });
  // Queries
  const contactFormApi = useQuery({
    url: "/forms/newsletter/",
    method: "POST",
    onSuccess: () => {
      contactForm.reset();
      setCheck(false);
      setMessage(true);
      setTimeout(() => {
        setMessage(false);
      }, 5000);
    },
  });
  const submitFormHandler = () => {
    if (contactForm.isValid() && check) {
      contactFormApi.executeQuery(contactForm.getApiBody());
    }
  };

  return (
    <footer className={Style.footer}>
      <Container>
        <div className={Style.footerGrid}>
          <div className={Style.company}>
            <Link to={paths.main.home}>
              <img src={logo} alt="" />
            </Link>
            <h6>
              QuantWise aims to simplify crypto trading through AI and
              automation, turning our members from observers to successful
              traders in the crypto marketplace.
            </h6>
            <h6>UNICAPITAL LTD</h6>
            <h6>2023/C0111</h6>
            <h6>43 Great George Street</h6>
            <h6>Roseau, Dominica</h6>
          </div>
          <div className={Style.links}>
            <h6>{links.header}</h6>
            {links.data.map((item, index) => {
              return (
                <Link to={item.link} key={index}>
                  {item.value}
                </Link>
              );
            })}
          </div>
          <div className={Style.links}>
            <h6>{resources.header}</h6>
            {resources.data.map((item, index) => {
              return item.internal === true ? (
                <Link to={item.link} key={index}>
                  {item.value}
                </Link>
              ) : (
                <a target="_blank" href={item.link} key={index}>
                  {item.value}
                </a>
              );
            })}
          </div>
          <div className={Style.letter}>
            <h6>Subscribe to our newsletter</h6>
            <InputField
              style={{
                border: "#FFFFFF66",
                background: "#12121A",
                height: "46px",
                color: "#777777",
                marginBlock: "35px 20px",
              }}
              placeholder={"Enter Valid Email"}
              className={Style.phone}
              {...contactForm.getInputProps("email")}
            />
            <div className={Style.accept} onClick={() => setCheck(!check)}>
              <div className={Style.checked}>
                {check && <BsFillCheckSquareFill className={Style.checkIcon} />}
              </div>
              <p>
                I accept the{" "}
                <a
                  href="https://docs.quantwise.ai/terms/terms-of-use"
                  target="_blank"
                >
                  Terms and Condition
                </a>
              </p>
            </div>
            {contactFormApi.isSuccess && message && (
              <span>Your message has been sent!"</span>
            )}
            <CustomButton
              isLoading={contactFormApi.isLoading}
              onClick={() => {
                if (contactForm.validate()) {
                  submitFormHandler();
                }
              }}
              style={{
                cursor: check ? "" : "not-allowed",
                marginLeft: "auto",
              }}
            >
              submit
            </CustomButton>
          </div>
        </div>
        <div className={Style.socials}>
          <a
            href="https://twitter.com/quantwiseai"
            target="_blank"
            rel="noreferrer"
          >
            <FaXTwitter className={Style.mediaIcon} />
          </a>
          <a
            href="https://www.facebook.com/quantwiseai"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="Facebook" />
          </a>
          <a
            href="https://www.instagram.com/quantwise.ai/"
            target="_blank"
            rel="noreferrer"
          >
            <BsInstagram className={Style.mediaIcon} />
          </a>
          <a
            href="https://www.youtube.com/channel/UC3-MsM64VxQO6PmPFhGdErg"
            target="_blank"
            rel="noreferrer"
          >
            <BsYoutube className={Style.mediaIcon} />
          </a>
          <a href="https://t.me/quantwise" target="_blank" rel="noreferrer">
            <img src={telegram} alt="Telegram" />
          </a>
          <a
            href="https://quantwise.medium.com/"
            target="_blank"
            rel="noreferrer"
          >
            <SiMedium className={Style.mediaIcon} />
          </a>
          <a
            href="https://www.crunchbase.com/organization/quantwise"
            target="_blank"
            rel="noreferrer"
          >
            <SiCrunchbase className={Style.mediaIcon} />
          </a>
        </div>
        <div className={Style.separator} />
        <p className={Style.rights}>© 2023 Quantwise. All rights reserved.</p>
      </Container>
    </footer>
  );
};

export default Footer;
