import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { useLocation } from "react-router-dom";

// Components
import CustomButton from "../CustomButton/CustomButton";

// Images
import boxes from "../../../Assets/images/creatorsBoxes.png";
import forexBG from "../../../Assets/images/currencyBG.png";
import cryptoBG from "../../../Assets/images/cryptogpt2.png";

// Styles
import Style from "./Creators.module.css";

const data = [
  {
    bg: cryptoBG,
    text: "CryptoGPT is the first AI capable of generating comprehensive analysis and profitable strategies in the cryptocurrency market. Learn about our AI.",
    title: "CryptoGPT",
    link: "/crypto-gpt",
  },
  {
    bg: forexBG,
    title: "ForexGPT",
    text: "Learn about our AI applied to Forex. ForexGPT is the first fully autonomous AI capable of operating in the markets ",
    // link: "/forex-gpt",
  },
];

const Creators = ({ title, subTitle }) => {
  const location = useLocation();
  return (
    <div className={Style.creatorsWrapper}>
      <img src={boxes} alt="first" />
      <img
        style={{
          display: location.pathname === "/our-ai" ? "none" : "block",
        }}
        src={boxes}
        alt="second"
      />
      <Container>
        <div className={Style.header}>
          <h1>{title}</h1>
          <h6>{subTitle}</h6>
        </div>
        <div className={Style.cards}>
          {data.map((item, index) => {
            return <Card key={index} {...item} />;
          })}
        </div>
      </Container>
    </div>
  );
};

const Card = ({ bg, title, text, link }) => {
  // TODO : get backgrounds from designers
  return (
    <div
      style={
        {
          // backgroundImage: `url(${bg})`,
        }
      }
      className={Style.card}
    >
      {/* <img src={bg} alt="" /> */}
      <div
        className={Style.cardImg}
        style={{
          backgroundImage: `url(${bg})`,
        }}
      ></div>
      <h2>{title}</h2>
      <h6>{text}</h6>
      <CustomButton className={Style.readMoreBtn} to={link}>
        discover
      </CustomButton>
    </div>
  );
};

export default Creators;
