import React from "react";

// Components
import HeroSection from "./Components/HeroSection/HeroSection";
import Awesome from "./Components/Awesome/Awesome";
import Products from "./Components/Products/Products";
import Options from "../../Components/UIKit/Options/Options";
// import Help from "../../Components/UIKit/Help/Help";

// Data
import { options } from "./data/data";

// Styles
import Style from "./ProductsView.module.css";

const ProductsView = () => {
  return (
    <>
      <section className={Style.hero}>
        <HeroSection />
      </section>
      <section>
        <Awesome />
      </section>
      <section>
        <Products />
      </section>
      <section className={Style.options}>
        <Options data={options} />
      </section>
      {/* <section>
        <Help />
      </section> */}
    </>
  );
};

export default ProductsView;
