import React, { useState } from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Icons
import pinkCheck from "../../../Assets/icons/pinkCheck.svg";

// Styles
import Style from "./Options.module.css";
import CustomButton from "../CustomButton/CustomButton";

const Options = ({ data }) => {
  return (
    <Container>
      <div className={Style.optionsGrid}>
        <div
          style={{
            backgroundImage: `url(${data.picture})`,
          }}
          className={Style.imgWrapper}
        />
        <div className={Style.options}>
          <h1>{data.title}</h1>
          <h6>{data.subTitle}</h6>
          {data.data.map((point, index) => {
            return <Point key={index} {...point} />;
          })}
          {/* {data.btnText && (
            <CustomButton
              style={{
                marginTop: "35px",
              }}
            >
              {data.btnText}
            </CustomButton>
          )} */}
        </div>
      </div>
    </Container>
  );
};

const Point = ({ title, text }) => {
  const [active, setActive] = useState(true);
  return (
    <div className={Style.point}>
      <div className={Style.pointHead} onClick={() => setActive(!active)}>
        <img src={pinkCheck} alt="" />
        <h6>{title}</h6>
      </div>
      {text && (
        <div className={Style.pointFoot}>
          <h6 className={active ? Style.activeText : Style.text}>{text}</h6>
        </div>
      )}
    </div>
  );
};

export default Options;
