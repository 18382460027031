import React, { useRef } from "react";

// Components
import HeroSection from "./Components/HeroSection/HeroSection";
import Success from "./Components/Success/Success";
import CustomRow from "../../Components/UIKit/CustomRow/CustomRow";
import BeSmarter from "../../Components/UIKit/BeSmarter/BeSmarter";
import Options from "../../Components/UIKit/Options/Options";

// Data
import { rowData } from "./data/data";
import { rowDataAI } from "./data/data";
import { options } from "./data/data";

// Styles
import Style from "./InstitutionalView.module.css";
import Dialog from "./Components/Dialog/Dialog";


const InstitutionalView = () => {

  return (
    <>
      <section>
        <HeroSection />
      </section>
      <section className={Style.hero}>
        <Success />
      </section>
      <section>
        <CustomRow data={rowData} />
      </section>
      <section>
        <Options data={options} />
      </section>
      <section>
        <CustomRow  data={rowDataAI} />
      </section>
      <section>
        <BeSmarter />
      </section>
    </>
  );
};

export default InstitutionalView;
