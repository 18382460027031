import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Icons
import Union from "../../../../Assets/icons/Union.svg";

// Styles
import Style from "./ClassRoom.module.css";
import CustomButton from "../../../../Components/UIKit/CustomButton/CustomButton";

const ClassRoom = () => {
  return (
    <Container>
      <div className={Style.room}>
        <img className={Style.topLeft} src={Union} alt="" />
        <img className={Style.topRight} src={Union} alt="" />
        <img className={Style.bottomLeft} src={Union} alt="" />
        <img className={Style.bottomRight} src={Union} alt="" />
        <div className={Style.roomGrid}>
          <div className={Style.roomLeft} />
          <div className={Style.roomRight}>
            <h1>Easy to use and Codeless</h1>
            <h6>
              Embrace a seamless trading experience with CryptoGPT. No
              programming knowledge? No problem. Our platform is designed to be
              user-friendly and accessible, making advanced AI-driven crypto
              trading as straightforward as a few clicks. Leave the complex
              algorithms to us and focus on watching your portfolio grow. No
              coding, no hassle, just smart investing.
            </h6>
            <CustomButton light>get started</CustomButton>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ClassRoom;
