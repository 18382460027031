import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Images
import heroBg from "../../../../Assets/images/productHeroBG.png";
import stars from "../../../../Assets/images/starsProductHero.png";

// Styles
import Style from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <div className={Style.wrapper}>
      <img src={heroBg} alt="" />
      <img src={stars} alt="" />
      <Container>
        <div className={Style.hero}>
          <h1>
            <div className={Style.backdrop} />
            {/* <span></span> */}
            Discover our Premium Report for the Crypto Market
            {/* <span></span>{" "} */}
          </h1>
          <h4>
            Uncover hidden gems, maximize your portfolio for the next bull run,
            and learn exclusive strategies with QuantWise - your gateway to
            superior crypto insights.{" "}
          </h4>
          <div className={Style.backdropOne} />
        </div>
      </Container>
    </div>
  );
};

export default HeroSection;
