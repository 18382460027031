import React from "react";

// Components
import CustomButton from "../../../../Components/UIKit/CustomButton/CustomButton";

// Paths
import { paths } from "../../../../Data/routes";

// Images
import infinity from "../../../../Assets/images/infinityImg.png";
import perfomanceImg from "../../../../Assets/images/perfomanceImg.png";
import safetyImg from "../../../../Assets/images/safetyImg.png";
import relyImg from "../../../../Assets/images/relyImg.png";
import historyImg from "../../../../Assets/images/historyImg.png";

// Styles
import Style from "./Power.module.css";
import { Container } from "@hybris-software/ui-kit";

const data = [
  {
    img: perfomanceImg,
    title: "Performance",
    subTitle:
      "Our AI have a proven track record of solid performance. Through  pattern recognition and predictive analysis.",
  },
  {
    img: safetyImg,
    title: "Safety",
    subTitle:
      "Our AI systems are designed with advanced security protocols to protect you and keep your trading safe.",
  },
  {
    img: relyImg,
    title: "Reliability",
    subTitle:
      "Our algorithms have been tested and trusted by a diverse global user base. ",
  },
  {
    img: historyImg,
    title: "History",
    subTitle:
      "With a reliable 3+ year track record, we have demonstrated  performance in various market conditions. ",
  },
];

const Power = () => {
  return (
    <Container>
      <div className={Style.power}>
        <img src={infinity} alt="Infinity" />
        <div className={Style.heading}>
          <h1>Introducing QuantWise's AI strategies: CryptoGPT and ForexGPT</h1>
          <h6>
            QuantWise's AI algorithms, CryptoGPT and ForexGPT, are designed to
            streamline your trading experience. Leveraging advanced artificial
            intelligence, these copytrading strategies offer robust performance
            in the world of crypto and forex trading respectively.
          </h6>
          <CustomButton >Get Started</CustomButton>
        </div>
        <div className={Style.cards}>
          {data.map((item, index) => {
            return <Card key={index} {...item} />;
          })}
        </div>
      </div>
    </Container>
  );
};

const Card = ({ title, subTitle, img }) => {
  return (
    <div className={Style.card}>
      <div className={Style.cardHead}>
        <img src={img} alt="" />
      </div>
      <div className={Style.cardFoot}>
        <h5>{title}</h5>
        <h6>{subTitle}</h6>
      </div>
    </div>
  );
};

export default Power;
