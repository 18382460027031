import React from "react";

// Components
import CustomButton from "../CustomButton/CustomButton";

// Icons
import pinkCheck from "../../../Assets/icons/pinkCheck.svg";

// Styles
import Style from "./PriceCard.module.css";

const PriceCard = ({
  title,
  subTitle,
  price,
  currency,
  discount,
  index,
  list,
}) => {
  return (
    <div
      style={{
        paddingBlock: index === 1 ? "50px" : "",
        height: "fit-content",
      }}
      className={Style.card}
    >
      <h2>{title}</h2>
      <h6>{subTitle}</h6>
      <div className={Style.price}>
        <h2>{price}</h2>
        <h4>{currency}</h4>
      </div>
      <div className={Style.discount}>
        <h4>
          Performance Fee {discount} {currency}
        </h4>
      </div>
      <CustomButton className={Style.tryButton}>Try free trial</CustomButton>
      <div className={Style.list}>
        {list.map((item, index) => {
          return <Point key={index} index={index} {...item} />;
        })}
      </div>
    </div>
  );
};

const Point = ({ text, index }) => {
  return (
    <div
      style={{
        border: index === 2 ? "none" : "",
      }}
      className={Style.point}
    >
      <img src={pinkCheck} alt="" />
      <h6>{text}</h6>
    </div>
  );
};

export default PriceCard;
