import React from "react";

// Libraries
import { Container, useWindowSize } from "@hybris-software/ui-kit";

// Utils
import classNames from "../../../../Utils/classNames";

// Images
import leftBlackHole from "../../../../Assets/images/leftBlackHole.png";
import rightBlackHole from "../../../../Assets/images/rightBlackHole.png";
import line from "../../../../Assets/images/tree.png";
import carouselYellow from "../../../../Assets/images/carouselYellow.png";
import carouselPink from "../../../../Assets/images/carouselPink.png";
import carouselGreen from "../../../../Assets/images/carouselGreen.png";
import carouselRed from "../../../../Assets/images/carouselRed.png";
import carousel from "../../../../Assets/images/carosel.png";

// Styles
import Style from "./RoadMap.module.css";

const data = [
  {
    carouselBG: carousel,
    title: "2020",
    text: "Started testing CryptoGPT-1",
    style: Style.One,
  },
  {
    carouselBG: carouselYellow,
    title: "2021",
    text: "Round Family and Friends for use the AI CryptoGPT.",
    style: Style.Two,
  },
  {
    carouselBG: carouselPink,
    title: "2021 - 2022",
    text: "Partner with Binance Broker and begin retail platform design.",
    style: Style.Three,
  },
  {
    carouselBG: carouselGreen,
    title: "2023",
    text: "New Strategic Partners, Allocated Team, Launch Platform on Event in Dubai",
    style: Style.Four,
  },
  {
    carouselBG: carouselRed,
    title: "2024",
    text: "Launch ForexGPT",
    style: Style.Five,
  },
];

const RoadMap = () => {
  return (
    <div className={Style.wrapper}>
      <div className={Style.heading}>
        <p>[&nbsp;&nbsp;&nbsp;&nbsp;ROADMAP&nbsp;&nbsp;&nbsp;&nbsp;]</p>
        <h1>Follow our Progress</h1>
      </div>
      <img src={leftBlackHole} alt="leftHole" />
      <img src={rightBlackHole} alt="rightHole" />
      <Container>
        <div className={Style.road}>
          <div className={Style.line} />

          {data.map((item, index) => {
            return <Carousel key={index} {...item} />;
          })}
        </div>
      </Container>
    </div>
  );
};

const Carousel = ({ title, text, carouselBG, style }) => {
  const width = useWindowSize();
  return (
    <div className={Style.card}>
      <div
        style={{
          background: `url(${carouselBG}) center / contain
    no-repeat, #020203 `,
        }}
        className={classNames(Style.carousel, style)}
      >
        <h4>{title}</h4>
        <h6>{text}</h6>
      </div>
      {style !== Style.Five && <div className={Style.bottomLine} />}
    </div>
  );
};

export default RoadMap;
