import React from "react";

// Components
import HeroSection from "./Components/HeroSection/HeroSection";
import Success from "./Components/Success/Success";
// import News from "./Components/News/News";
import RoadMap from "./Components/RoadMap/RoadMap";

// Styles
import Style from "./AboutUsView.module.css";
import BeSmarter from "../../Components/UIKit/BeSmarter/BeSmarter";

const AboutUsView = () => {
  return (
    <>
      <section className={Style.hero}>
        <HeroSection />
      </section>
      <section>
        <Success />
      </section>
      <section>
        <RoadMap />
      </section>
      {/* <section>
        <News />
      </section> */}
      <section>
        <BeSmarter />
      </section>
    </>
  );
};

export default AboutUsView;
