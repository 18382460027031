import React from "react";

// Styles
import Style from "./TextSlider.module.css";

const TextSlider = () => {
  return (
    <div className={Style.wrap}>
      <p className={Style.movingText}>Quantwise</p>
      <p className={Style.movingText}>Quantwise</p>
      <p className={Style.movingText}>Quantwise</p>
      <p className={Style.movingText}>Quantwise</p>
      <p className={Style.movingText}>Quantwise</p>
      <p className={Style.movingText}>Quantwise</p>
      <p className={Style.movingText}>Quantwise</p>
    </div>
  );
};

export default TextSlider;
