import React from "react";

// Components
import HeroSection from "./Components/HeroSection/HeroSection";
import BeSmarter from "../../Components/UIKit/BeSmarter/BeSmarter";
import HowItWorks from "./Components/HowItWorks/HowItWorks";
import ClassRoom from "./Components/ClassRoom/ClassRoom";
import Features from "./Components/Features/Features";

// Styles
import Style from "./CryptoGPTView.module.css";

const CryptoGPTView = () => {
  return (
    <>
      <section>
        <HeroSection />
      </section>
      <section className={Style.howItWorks}>
        <HowItWorks />
      </section>
      <section>
        <ClassRoom />
      </section>
      <section>
        <Features />
      </section>
      <section className={Style.smart}>
        <BeSmarter slider />
      </section>
    </>
  );
};

export default CryptoGPTView;
