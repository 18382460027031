import React from "react";

// Style

import Style from "./CryptoSliders.module.css";
const cryptoData = [
  {
    id: 1,
    name: "QUANTUM",
  },
  {
    id: 2,
    name: "-",
  },
  {
    id: 3,
    name: "CRYPTO GPT",
  },
  {
    id: 4,
    name: "-",
  },
  {
    id: 5,
    name: "FOREX GPT",
  },
  {
    id: 6,
    name: "-",
  },
  {
    id: 7,
    name: "FOREX",
  },
  {
    id: 8,
    name: "-",
  },
  {
    id: 9,
    name: "CRYPTO",
  },
  {
    id: 10,
    name: "-",
  },
  {
    id: 11,
    name: "AI",
  },
  {
    id: 12,
    name: "-",
  },
  {
    id: 13,
    name: "ANALISYS",
  },
];

const CryptoSliders = () => {
  return (
    <div className={Style.wrapper}>
      <div className={Style.cryptoSliders}>
        <div className={Style.scroll}>
          <div className={Style.lScroll}>
            {cryptoData.map((crypto) => (
              <span key={crypto.id}>
                <SingleCrypto
                  size={"60px"}
                  image={crypto.icon}
                  title={crypto.name}
                />
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className={Style.cryptoSliders}>
        <div className={Style.scroll}>
          <div className={Style.mScroll}>
            {cryptoData.map((crypto) => (
              <span key={crypto.id}>
                <SingleCrypto
                  size={"80px"}
                  image={crypto.icon}
                  title={crypto.name}
                />
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className={Style.cryptoSliders}>
        <div className={Style.scroll}>
          <div className={Style.lScroll}>
            {cryptoData.map((crypto) => (
              <span key={crypto.id}>
                <SingleCrypto
                  size={"120px"}
                  image={crypto.icon}
                  title={crypto.name}
                />
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const SingleCrypto = ({ image, title, size }) => {
  return (
    <div className={Style.wrapperSingle}>
      <div className={Style.SingleCrypto}>
        {/* <img src={image} alt="" className={Style.image} /> */}
        <p
          style={{
            fontSize: size,
          }}
          className={Style.title}
        >
          {title}
        </p>
      </div>
    </div>
  );
};
export default CryptoSliders;
