import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Images
import grad from "../../../../Assets/images/instGradient.png";

// Styles
import Style from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <div className={Style.wrapper}>
      <img src={grad} alt="" />
      <Container>
        <div className={Style.hero}>
          <h1>Join the Institutional revolution with Quantwise</h1>
          <h6>
            Learn how we harness the power of data combined with our highly
            institutional strategies. Work with us.
          </h6>
        </div>
      </Container>
    </div>
  );
};

export default HeroSection;
