import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Components
import CustomButton from "../../../../Components/UIKit/CustomButton/CustomButton";

// Images
import skeleton from "../../../../Assets/images/robotPointing.png";
import activeUsers from "../../../../Assets/images/activeUsers.png";
import coins from "../../../../Assets/images/coins.png";
import circles from "../../../../Assets/images/circlesHero.png";

// Icons
import Union from "../../../../Assets/icons/Union.svg";

// Styles
import Style from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <Container>
      <div className={Style.heroPad} />
      <div className={Style.hero}>
        <img className={Style.topLeft} src={Union} alt="" />
        <img className={Style.topRight} src={Union} alt="" />
        <img className={Style.bottomLeft} src={Union} alt="" />
        <img className={Style.bottomRight} src={Union} alt="" />
        <div className={Style.heading}>
          <h1>
            Discover the <span>Performance</span> of our AI
          </h1>
          <h4>
            Never before has anyone been able to manage copytrading portfolios
            completely independently without human error. Learn more about
            QuantWise, the market leader for strategies totally managed by
            Artificial Intelligence.
          </h4>
          <CustomButton light>get started</CustomButton>
        </div>
        <img src={activeUsers} alt="" />
        <img src={coins} alt="" />
        <div className={Style.userCoins}>
          <img src={activeUsers} alt="" />
          <img src={coins} alt="" />
        </div>
        <div className={Style.skeleton}>
          <img src={skeleton} alt="" />
        </div>
        <img className={Style.circles} src={circles} alt="" />
        <div className={Style.leftRedMoon} />
        <div className={Style.leftGreenMoon} />
        <div className={Style.rightGreenMoon} />
        <div className={Style.purpleMoon} />
      </div>
    </Container>
  );
};

export default HeroSection;
