import React from "react";

// Libraries
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// Utilities
import classNames from "../../../Utils/classNames";

// Components
import { Button } from "@hybris-software/ui-kit";

// Style
import Theme from "../../../Assets/css/Theme.module.css";
import Style from "./CustomButton.module.css";

const CustomButton = ({ children, baseClassName, to, disabled, ...props }) => {
  const navigate = useNavigate();
  return (
    <Button
      className={classNames(baseClassName, props.light && Theme.lightBtn)}
      {...props}
      onClick={() => {
        if (to) {
          navigate(to);
        } else if (props.onClick) {
          props?.onClick();
        }
      }}
    >
      {/* {props.icon && <BsSendFill />} */}
      {/* <div className={Style.btnWrapper}> */}
      <div className={props.light ? Style.triangleLight : Style.triangle} />
      <div className={Style.triangleTwo} />
      {to || props.onClick ? (
        <div className={props.light ? Style.btnLight : Style.btn}>
          <p className={Style.btnChildren}>{children}</p>
        </div>
      ) : (
        <div className={props.light ? Style.comingLight : Style.coming}>
          <p className={Style.btnChildren}>{children}</p>
        </div>
      )}

      {/* </div> */}
    </Button>
  );
};

// check props type
CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomButton;
